import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';
import { BookingsAPI } from '../../api/BookingsApi';
import { WidgetViewModel } from '../../viewModel/viewModel';
import { EnrichedService } from '../../types/types';
import { WeekDay } from '@wix/bookings-uou-domain';

export type GetAdditionalServicesData = () => Promise<void>;

export const createGetAdditionalServicesData =
  ({
    widgetViewModel,
    bookingsApi,
    setProps,
    isServiceListEventsClassDaysEnable,
  }: {
    widgetViewModel: WidgetViewModel;
    bookingsApi: BookingsAPI;
    setProps: Function;
    isServiceListEventsClassDaysEnable: boolean;
  }): GetAdditionalServicesData =>
  async () => {
    const { coursesAvailability, services } = await getAdditionalServicesData({
      services: widgetViewModel.services,
      bookingsApi,
      isServiceListEventsClassDaysEnable,
    });

    widgetViewModel.services = services;
    widgetViewModel.coursesAvailability = coursesAvailability;

    setProps({
      widgetViewModel: {
        ...widgetViewModel,
      },
    });
  };

const getAndPopulateClassOfferedDays = async ({
  services,
  bookingsApi,
  isServiceListEventsClassDaysEnable,
}: {
  services: EnrichedService[];
  bookingsApi: BookingsAPI;
  isServiceListEventsClassDaysEnable: boolean;
}) => {
  const classServiceIds = services
    .filter(({ type, dummy }) => !dummy && type === ServiceType.CLASS)
    .map(({ id }) => id!);

  const scheduleIds = services
    .filter(({ type, dummy }) => !dummy && type === ServiceType.CLASS)
    .map((service) => {
      return service.schedule?.id!;
    });

  if (!classServiceIds.length || !scheduleIds.length) {
    return services;
  }

  let classesOfferedDays: Record<string, WeekDay[]>;

  if (isServiceListEventsClassDaysEnable) {
    classesOfferedDays = await bookingsApi.getEventsOfferedDays(scheduleIds);
  } else {
    classesOfferedDays = await bookingsApi.getOfferedDays(classServiceIds);
  }

  services
    .filter((service) => service.type === ServiceType.CLASS)
    .forEach((service) => {
      const serviceOfferedDays =
        classesOfferedDays[
          isServiceListEventsClassDaysEnable
            ? service?.schedule?.id!
            : service.id!
        ] || [];
      if (serviceOfferedDays) {
        service.offeredDays = serviceOfferedDays;
      }
    });

  return services;
};

const getServicesAvailability = ({
  services,
  bookingsApi,
}: {
  services: EnrichedService[];
  bookingsApi: BookingsAPI;
}) => {
  const serviceIds = services
    .filter(({ type, dummy }) => !dummy && type === ServiceType.COURSE)
    .map(({ id }) => id!);

  if (!serviceIds.length) {
    return;
  }

  return bookingsApi.getServicesAvailability(serviceIds);
};

export const getAdditionalServicesData = async ({
  services,
  bookingsApi,
  isServiceListEventsClassDaysEnable,
}: {
  services: EnrichedService[];
  bookingsApi: BookingsAPI;
  isServiceListEventsClassDaysEnable: boolean;
}) => {
  const [servicesWithOfferedDays, coursesAvailability] = await Promise.all([
    getAndPopulateClassOfferedDays({
      services,
      bookingsApi,
      isServiceListEventsClassDaysEnable,
    }).catch((e) => {
      console.log(e);
      return services;
    }),
    getServicesAvailability({ services, bookingsApi })?.catch((e) => {
      console.log(e);
      return undefined;
    }),
  ]);

  return { services: servicesWithOfferedDays, coursesAvailability };
};
